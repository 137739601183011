<template>
  <section id="dashboard">
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <div v-if="load">
        <div v-if="roles">
          <dashboard-gestor v-if="roles == rol.gestorUser" />
          <dashboard-publi v-if="roles == rol.publi" />
          <dashboard-publicador v-if="roles == rol.publicadorCont" />
          <dashboard-dessign v-if="roles == rol.dis" />
          <dashboard-editor v-if="roles == rol.editorCont" />
          <dashboard-vendor v-if="roles == rol.vendor" />
          <dashboard-sponsor v-if="roles == rol.sponsor" />
          <dashboard-statistics v-if="roles == rol.gestorStatis" />
          <dashboard-notifications v-if="roles == rol.gestorNotifications" />
          <dashboard-super
            v-if="roles != rol.editorCont &&
              roles != rol.dis &&
              roles != rol.vendor &&
              roles != rol.sponsor &&
              roles != rol.publicadorCont &&
              roles != rol.publi &&
              roles != rol.gestorStatis &&
              roles != rol.gestorUser &&
              roles != rol.gestorNotifications
            "
          />
        </div>
        <div v-else>
          <dashboard-super />
        </div>
      </div>
    </b-overlay>
    <!-- <dashboard-super v-if="roles == 'Administrador'" /> -->
  </section>
</template>

<script>
import * as constants from '@core/utils/constants'

import {
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import store from '@/store'
import { getUserData } from '@/auth/utils'

const DashboardSuper = () => import('./DashboardRoles/DashboardSuper.vue')
const DashboardEditor = () => import('./DashboardRoles/DashboardEditor.vue')
const DashboardSponsor = () => import('./DashboardRoles/DashboardSponsor.vue')
const DashboardVendor = () => import('./DashboardRoles/DashboardVendor.vue')
const DashboardGestor = () => import('./DashboardRoles/DashboardGestor.vue')
const DashboardPubli = () => import('./DashboardRoles/DashboardPubli.vue')
const DashboardDessign = () => import('./DashboardRoles/DashboardDessign.vue')
const DashboardStatistics = () => import('./DashboardRoles/DashboardStatistics.vue')
const DashboardPublicador = () => import('./DashboardRoles/DashboardPublicador.vue')
const DashboardNotifications = () => import('./DashboardRoles/DashboardNotifications.vue')

export default {
  components: {
    DashboardPublicador,
    DashboardDessign,
    DashboardVendor,
    DashboardEditor,
    DashboardSponsor,
    DashboardPubli,
    DashboardSuper,
    DashboardGestor,
    DashboardStatistics,
    DashboardNotifications,
    BOverlay,
  },
  data() {
    return {
      roles: null,
      data: {},
      show: false,
      load: false,
      rol: constants,
    }
  },
  created() {
    this.show = true

    const userData = getUserData()
    const query = `{
      client(id: "${userData.profile.client.id}") {
        id
        name
        totalVideos
        totalProducts
        totalInteractivity
        contentInteractivityLimit
        initialCatLimit
        nestedCatLimit
        initialCatProductLimit
        nestedCatProductLimit
        productsCatLimit
      }
    }`
    axios.post('', { query }).then(response => {
      messageError(response, this)

      const data = response.data.data.client
      const platformConfig = {
        initialCategoryLimit: data.initialCatLimit,
        nestedCategoryLimit: data.nestedCatLimit,
        totalContentsLimit: data.totalVideos,
        totalProductsLimit: data.totalProducts,
        totalInteractivityLimit: data.totalInteractivity,
        contentInteractivityLimit: data.contentInteractivityLimit,
      }
      store.commit('app/SET_PLATFORM_CONFIGURATION', platformConfig)
    }).catch(err => {
      // eslint-disable-next-line
      console.log(err)
      showToast(this.$t('serverError'), 2, this)
    })

    /* ROLES */

    axios
      .post('', {
        query: `query{
          allCustomUsers(id:"${userData.id}") {
            edges {
              node {
                id
                username
                groups {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }`,
      })
      .then(result => {
        messageError(result, this)
        this.show = false
        this.load = true

        this.roles = result.data.data.allCustomUsers.edges[0].node.groups.edges[0].node.name
      })
      .catch(() => {
        this.roles = null
      })
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
