var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard"}},[_c('b-overlay',{attrs:{"variant":"transparent","show":_vm.show,"opacity":1.00,"blur":'2px',"rounded":"lg"}},[(_vm.load)?_c('div',[(_vm.roles)?_c('div',[(_vm.roles == _vm.rol.gestorUser)?_c('dashboard-gestor'):_vm._e(),(_vm.roles == _vm.rol.publi)?_c('dashboard-publi'):_vm._e(),(_vm.roles == _vm.rol.publicadorCont)?_c('dashboard-publicador'):_vm._e(),(_vm.roles == _vm.rol.dis)?_c('dashboard-dessign'):_vm._e(),(_vm.roles == _vm.rol.editorCont)?_c('dashboard-editor'):_vm._e(),(_vm.roles == _vm.rol.vendor)?_c('dashboard-vendor'):_vm._e(),(_vm.roles == _vm.rol.sponsor)?_c('dashboard-sponsor'):_vm._e(),(_vm.roles == _vm.rol.gestorStatis)?_c('dashboard-statistics'):_vm._e(),(_vm.roles == _vm.rol.gestorNotifications)?_c('dashboard-notifications'):_vm._e(),(_vm.roles != _vm.rol.editorCont &&
            _vm.roles != _vm.rol.dis &&
            _vm.roles != _vm.rol.vendor &&
            _vm.roles != _vm.rol.sponsor &&
            _vm.roles != _vm.rol.publicadorCont &&
            _vm.roles != _vm.rol.publi &&
            _vm.roles != _vm.rol.gestorStatis &&
            _vm.roles != _vm.rol.gestorUser &&
            _vm.roles != _vm.rol.gestorNotifications
          )?_c('dashboard-super'):_vm._e()],1):_c('div',[_c('dashboard-super')],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }